export const cogcPrograms = [
  { value: "ALL", display: "All Programs" },
  { value: null, display: "No Program" },
  { value: "ADVERTISING_RESOURCE_EXTRACTION", display: "Resource Ext." },
  { value: "ADVERTISING_CHEMISTRY", display: "Chemistry" },
  { value: "ADVERTISING_METALLURGY", display: "Metallurgy" },
  { value: "ADVERTISING_MANUFACTURING", display: "Manufacturing" },
  { value: "ADVERTISING_AGRICULTURE", display: "Agriculture" },
  { value: "ADVERTISING_FOOD_INDUSTRIES", display: "Food Industries" },
  { value: "ADVERTISING_CONSTRUCTION", display: "Construction" },
  { value: "ADVERTISING_FUEL_REFINING", display: "Fuel Refining" },
  { value: "ADVERTISING_ELECTRONICS", display: "Electronics" }
];